$rtk-blue: #17a4e0;
$rtk-lt-blue: #e0eaf2;
$rtk-grey: #a5a5a5;
$rtk-lt-grey: #f4f5f5;
$rtk-md-grey: #e0e0e0;
$rtk-dk-grey: #7a8184;
$rtk-brown: rgb(111, 88, 75);
$rtk-orange: hsl(30, 100%, 60%);
$rtk-green: rgb(133, 209, 145);
$rtk-red: rgb(166, 38, 57);
$rtk-bright-red: #e60000;
$rtk-black: #343434;
$rtk-realblack: #000000;
$rtk-white: #fff;
$rtk-gold: #ffd700;
$rtk-menu-grey: #f5f5f5;
