@import '../styles/rtkcolors.scss';

.rtk-AppBar {
  background-color: $rtk-realblack !important;
}

.icon-button-text {
  color: $rtk-gold;
  margin-left: 6px;
  font-size: 11px;
  font-weight: bold;
}

.divMenu:hover {
  background-color: $rtk-blue;
}

.AppMenu .MuiPaper-root {
  max-width: 610px;
}

.AppMenuItem, .AppMenuItem a  {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  //width: 60px;
  margin: 15px 30px !important;
  cursor: pointer;
  text-decoration: none !important;
}

.AppMenuIcon {
  height: 60px !important;
  width: 60px !important;
  padding: 10px;
  border: 1px solid $rtk-menu-grey;
  border-radius: 4pt;
}

.AppMenuCaption {
  display: block;
  color: $rtk-black;
  font-weight: 500;
  font-size: 0.9em;
  padding: 4px;
  margin-top: 10px;
  background-color: $rtk-white;
  border-radius: 4pt;
}

.AppMenuItem:hover .AppMenuCaption {
  color: $rtk-white;
  background-color: $rtk-blue;
  border-radius: 4pt;
}

.AppMenuCaption,
a:link,
a:visited {
  text-decoration: none;
}

.MsgIcon {
  position: relative;
  top: 6px;
  left: 0;
  height: 20px !important;
  width: 20px !important;
}

.right {
  float: right;
}

.MuiIconButton-root {
  border-radius: 0%!important;
}